import '../index.css'

const Logo = () => {
    return (
        <div>
            <div  className='text'>
                <h1 className='color'>OOPS</h1>
                <h1 className='color'>WEB IS UNDER DEVELOPMENT</h1>
            </div>
        </div>
    )
}

export default Logo