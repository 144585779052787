import '../src/index.css'
import Logo from './components/Logo';

function App() {
  return (
    <div className='bg'>
      <Logo />
    </div>
  );
}

export default App;
